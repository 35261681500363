.accordian__wrapper {
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
}

.details {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	background-color: var(--COLOR-BG-DETAILS);
	margin: 0 1rem;
	padding: 6px;
	border-radius: 4px;
	height: fit-content;
	gap: 1rem;
	width: 65vw;
}

.details:hover {
	background-color: var(--COLOR-BG-HOVER-DETAILS);
}

summary {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	padding: 8px;
}

summary i {
	font-size: 2.6rem;
}

details > summary {
	list-style: none;
}

@media only screen and (max-width: 980px) {
	.details {
		width: 55vw;
	}
}

@media only screen and (max-width: 780px) {
	.details {
		width: 85vw;
	}
	.accordian__wrapper {
		align-items: center;
	}
}

@media only screen and (max-width: 780px) {
	.accordian__wrapper {
		margin-bottom: 0.5rem;
	}
}
