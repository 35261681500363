.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--COLOR-PRIMARY);
	color: var(--COLOR-WHITE);
	grid-area: footer;
}

.footer * {
	color: var(--COLOR-WHITE);
}

