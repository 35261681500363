:root {
	--COLOR-TXT-PRIMARY: var(--COLOR-PRIMARY);
}

.body {
	background-color: var(--COLOR-BG);
	display: grid;
	min-height: 100vh;
	grid-template-columns: 1fr;
	grid-template-rows: 6rem 1fr 4rem;
	grid-template-areas:
		"header"
		"main"
		"footer";
	transition: all 0.3s ease-in;
}

.main--homepage {
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem 10rem;
	gap: 3rem;
	grid-area: main;
}

.section__heading {
	color: var(--COLOR-TXT);
	font-weight: 500;
}

/* categories section */
.homepage__section {
	display: flex;
	flex-flow: column nowrap;
	gap: 3rem;
	align-items: center;
}

.categories__items {
	display: flex;
	flex-flow: row wrap;
	gap: 2rem;
	margin: auto;
	text-align: center;
	width: 100%;
	margin: auto;
}

.categories__list {
	list-style-type: none;
}

ul {
	padding: 0;
}

.categories__links {
	position: relative;
	text-decoration: none;
}

.categories__links > img {
	width: 20rem;
}

.categories__links > span {
	background-color: var(--COLOR-PRIMARY);
	display: flex;
	font-size: 2.2rem;
	font-weight: 700;
	justify-content: center;
	opacity: 0.7;
	position: absolute;
	top: 12.7rem;
	width: 22rem;
	z-index: 3;
	color: var(--COLOR-WHITE);
}

/* promo-section  */
.promotion_container .image--responsive {
	margin: auto;
	max-height: 58rem;
	width: auto;
	aspect-ratio: auto;
}

/* items_container section */

.items_container .categories__items {
	gap: 3rem;
}

.image--responsive {
	object-fit: contain;
	aspect-ratio: 1/1;
}

.image--responsive:hover {
	object-fit: contain;
	transform: none;
}

body .btn {
	box-shadow: none;
}

@media only screen and (max-width: 780px) {
	.body {
		grid-template-areas:
			"header"
			"search"
			"main"
			"footer";
		grid-template-columns: 1fr;
		grid-template-rows: 6rem 5rem 1fr 4rem;
		height: auto;
	}

	.categories__links > img {
		max-width: 16rem;
	}
}
