li .card {
	/* min-height: 44rem; */
	display: flex;
	flex-flow: column;
	gap: 1rem;
	justify-content: space-between;
}

li .card__footer {
	margin-bottom: 4px;
}
