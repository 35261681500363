/* .card .like:hover {
	background-color: default;
	color: default;
} */

.card .like:hover {
	background-color: inherit;
	color: var(--COLOR-PRIMARY);
	box-shadow: none;
	opacity: 1;
}
.card .like {
	background-color: inherit;
	border: none;
	border-radius: 5px;
	box-shadow: none;
	opacity: 1;
}
.card .like i {
	font-size: 3rem;
	color: var(--COLOR-PRIMARY-THEME);
}

.card--horizontal_v2 .card--cart .card--body {
	padding: 0 2rem;
}

.card--cart .card__body {
	min-width: 25rem;
	padding: 5px 2.5rem;
}

.categories__items .categories__list .card {
	border: 0;
}
