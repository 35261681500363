.loader_wrapper {
	position: fixed;
	width: 10rem;
	height: 10rem;
	top: 45%;
    left: 50%;
	transform: translateY(-50%) translateX(-50%);
	z-index: var(--z-index-4);
}

.modal_container .modal {
	border-radius: 4px;
	border: 0;
	background-color: var(--COLOR-BG);
	max-width: 600px;
	padding: 1rem;
    z-index: var(--z-index-4);
}

.modal_container {
	position: fixed;
	/* backdrop-filter: blur(2px); */
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: var(--z-index-4);
}
