@import url(https://unpkg.com/mockman-js@latest/dist/style.css);
@import url(https://magnificentui.netlify.app/src/magnificent.css);

.toast__position {
	bottom: 6rem;
}

/* MEDIA QUERIES  */

@media only screen and (max-width: 900px) {
	html {
		font-size: 56%;
	}

	.main--homepage {
		display: flex;
		flex-flow: column nowrap;
		padding: 1rem 1rem;
	}

	.categories__items {
		justify-content: center;
	}

	.promotion_container .image--responsive {
		width: 98vh;
	}

	body .cart__wrapper {
		flex-flow: column wrap;
		align-items: center;
		grid-gap: 5rem;
		gap: 5rem;
		padding-bottom: 2rem;
	}

	body .main--cart,
	body .main--checkout {
		display: flex;
		flex-flow: column nowrap;
		padding: 1rem 2rem;
	}

	body .billing_section {
		max-width: 95vw;
		width: 35rem;
		margin: auto;
		margin-top: 0;
	}

	body button {
		font-size: 1.6rem;
	}

	body .main--homepage {
		display: flex;
		flex-flow: column nowrap;
		padding: 3rem 2rem;
	}

	body .categories__items {
		grid-gap: 1rem;
		gap: 1rem;
		padding-left: 0rem;
	}
}
@media only screen and (max-width: 780px) {
	html {
		font-size: 55%;
	}

	:root {
		--WIDTH-MAIN-CONTENT: 100%;
	}

	.body {
		grid-template-areas:
			"header"
			"search"
			"nav"
			"main";
		grid-template-columns: 1fr;
		grid-template-rows: 6rem 5rem 1fr 4rem;
		height: auto;
	}

	body .search_box {
		display: flex;
		justify-content: center;
		background-color: var(--COLOR-WHITE);
		border-radius: 4px;
		overflow: hidden;
		height: 4rem;
	}

	.aside--filter {
		display: none;
	}

	.filter__modal {
		position: absolute;
	}

	body .filter > * {
		font-size: 1.8rem;
	}

	body .filter__icon {
		display: flex;
	}

	body .product_page_wrapper {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: "main";
	}

	body > .header,
	.main {
		padding: 0;
		padding: 1.5rem 3rem 1rem;
	}

	body .display--modifier {
		display: none;
	}

	.social__handles {
		padding-right: 0;
	}

	.nav {
		display: none;
		font-size: 2rem;
	}

	.hamburger_icon {
		display: block;
	}

	.show .nav {
		display: flex;
		z-index: var(--z-index-4);
		position: -webkit-sticky;
		position: sticky;
		top: 50px;
		padding-top: 0;
		padding: 0.2rem 0.6rem;
	}

	.show .main {
		display: none;
	}

	.header .search_on_header {
		display: none;
	}

	.search_on_mobile {
		display: flex;
		width: 100%;
	}

	body .form {
		min-width: 40rem;
	}
}

@media only screen and (max-width: 500px) {
	html {
		font-size: 52%;
	}

	.comp_container .brand__text {
		margin-left: 0;
		margin: 0 auto;
	}

	.header__nav_btns {
		grid-gap: 1rem;
		gap: 1rem;
	}

	body .brand__text {
		cursor: pointer;
		font-size: 2.2rem;
	}

	body .form {
		min-width: 30rem;
	}
}

@media only screen and (max-width: 400px) {
	html {
		font-size: 47%;
	}

	.nav__links {
		width: 10rem;
	}

	.social__links {
		font-size: 3rem;
	}

	body .brand__text {
		cursor: pointer;
		font-size: 1.8rem;
	}

	body .header__nav_btns {
		display: flex;
		grid-gap: 7px;
		grid-gap: 7px;
		gap: 7px;
		padding-right: 0;
	}
}


.alert {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border: 0;
	border-radius: 1rem;
	color: var(--COLOR-WHITE);
	cursor: default;
	font-weight: 500;
	padding: 1.2rem 1.5rem;
	text-align: left;
	position: absolute;
	top: 10rem;
	right: 1.5rem;
	z-index: var(--z-index-2);
}

.alert i {
	color: var(--COLOR-WHITE);
	font-size: 1.5rem;
}

.alert--danger {
	background-color: #c84949;
	border: 0;
}


@media only screen and (max-width: 860px) {
	.alert {
		top: 13rem;
	}

	body .note_editor_section {
		padding: 1.5rem;
		padding-bottom: 0;
	}

	.note_lisiting_section > h3 {
		text-align: center;
	}

	.scroll_to_top_icon {
		bottom: 12rem;
	}
}

.loader_wrapper {
	position: fixed;
	width: 10rem;
	height: 10rem;
	top: 45%;
    left: 50%;
	-webkit-transform: translateY(-50%) translateX(-50%);
	        transform: translateY(-50%) translateX(-50%);
	z-index: var(--z-index-4);
}

.modal_container .modal {
	border-radius: 4px;
	border: 0;
	background-color: var(--COLOR-BG);
	max-width: 600px;
	padding: 1rem;
    z-index: var(--z-index-4);
}

.modal_container {
	position: fixed;
	/* backdrop-filter: blur(2px); */
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: var(--z-index-4);
}




.header {
	align-items: center;
	border-bottom: 2px solid var(--COLOR-PRIMARY);
	box-shadow: 0 3px 1px -2px var(--COLOR-BOX-SHADOW1),
		0 2px 2px var(--COLOR-BOX-SHADOW2), 0 1px 5px var(--COLOR-BOX-SHADOW3);
	display: flex;
	grid-area: header;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: var(--COLOR-BG);
	z-index: var(--z-index-3);
}

/* header--nav--actions  */

.header__nav_btns {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	padding-right: 2rem;
	align-items: center;
}

.header__nav_btns button {
	padding: 9px 1rem;
}

.header__nav_btns .badge_base {
	padding: 2px;
}

.header__nav_btns .badge {
	width: 2.2rem;
	height: 2.2rem;
	line-height: 2.2rem;
	top: -1rem;
	right: -1.2rem;
	font-size: 1.2rem;
	font-weight: 700;
}

.header__links,
.brand__text {
	text-decoration: none;
	color: var(--COLOR-TXT);
	border: none;
	background-color: transparent;
	box-shadow: none;
	padding: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 5px;
	gap: 5px;
}

.header__links:hover,
.brand__text:hover {
	color: var(--COLOR-PRIMARY-THEME);
}

.header__links:active {
	color: var(--COLOR-ACTIVE);
}

.brand__text {
	cursor: pointer;
	font-size: 3rem;
	font-weight: 500;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	color: var(--COLOR-PRIMARY-THEME);
	letter-spacing: 1.2px;
}

.logo__img {
	display: inline-block;
	cursor: pointer;
	height: 2.5rem;
}

/* Search BOX  */

.search_box {
	display: flex;
	justify-content: center;
	background-color: var(--COLOR-WHITE);
	border-radius: 4px;
	overflow: hidden;
	height: 4rem;
}

.search_box .input_box {
	font-size: 1.4rem;
	width: 30rem;
	padding: 7px 1rem;
	border: none;
	color: var(--COLOR-BLACK);
	background-color: transparent;
}

.search_box .btn--icon {
	border-radius: 0;
}

.search_on_mobile {
	display: none;
	grid-area: search;
	width: 100%;
}

@media only screen and (max-width: 780px) {
	.search_on_mobile {
		display: flex;
		justify-content: center;
		border-radius: 4px;
		overflow: hidden;
		height: 4.5rem;
		width: 100%;
		padding: 0 1rem;
		top: 8rem;
		position: -webkit-sticky;
		position: sticky;
		z-index: var(--z-index-4);
	}

	.search_on_mobile .input_box {
		width: 100%;
		background-color: var(--COLOR-BG);
	}
}

.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--COLOR-PRIMARY);
	color: var(--COLOR-WHITE);
	grid-area: footer;
}

.footer * {
	color: var(--COLOR-WHITE);
}


html[current_theme="light"] {
	--COLOR-BG: #e6f0ff;
	--COLOR-TXT: #000000;
	--COLOR-TXT-OPP: white;
	--COLOR-TXT-PRIMARY: #2255a4;
	--COLOR-PRIMARY-THEME: #2255a4;
	--COLOR-PRIMARY-OPP: white;
	--COLOR-OFFWHITE: #d3dded;
	--COLOR-CARD-BG: white;
	--COLOR-PRIMARY-DARK: #e6f0ff;
	--COLOR-CARD-BOX-SHADOW0: rgb(0 0 0 / 20%);
	--COLOR-CARD-BOX-SHADOW1: rgba(0, 0, 0, 0.56);
	--COLOR-CARD-BOX-SHADOW1: #0000001f;
	--COLOR-CARD-BOX-SHADOW2: #32325d1a;
	--COLOR-CARD-BOX-SHADOW3: #324dc549;

	/* Accordian */
	--COLOR-BG-DETAILS: #ffffff;
	--COLOR-BG-HOVER-DETAILS: rgba(193, 188, 188, 0.26);

	/* Address Card */
	--COLOR-BG-ADDRESS-CARD: #c2d8fa;
	--COLOR-BG-HOVER-ADDRESS-CARD: #e6f0ff;
}

html[current_theme="dark"] {
	--COLOR-BG: #172842;
	--COLOR-TXT: white;
	--COLOR-TXT-OPP: #000000;
	--COLOR-TXT-PRIMARY: #c6ddff;
	--COLOR-PRIMARY-THEME: white;
	--COLOR-PRIMARY-OPP: #2255a4;
	--COLOR-OFFWHITE: #2255a4;
	--COLOR-CARD-BG: #0c1e3a;
	--COLOR-PRIMARY-DARK: #163d77;
	--COLOR-CARD-BOX-SHADOW0: rgb(0 0 0 / 20%);
	--COLOR-CARD-BOX-SHADOW1: #ccd9faa9;
	--COLOR-CARD-BOX-SHADOW1: #4b536766;
	--COLOR-CARD-BOX-SHADOW2: #32325d1a;
	--COLOR-CARD-BOX-SHADOW3: #324dc549;

	/* Accordian */
	--COLOR-BG-DETAILS: #264e7f;
	--COLOR-BG-HOVER-DETAILS: #2d5c95;

	/* Address Card */
	--COLOR-BG-ADDRESS-CARD: #2b569c;
	--COLOR-BG-HOVER-ADDRESS-CARD: #1a4c97;
}

.set_visibility_hidden {
	visibility: hidden;
}

html .btn--icon i {
	font-size: 2.8rem;
}

button {
	font-size: 1.8rem;
}

.auth__btn {
	padding: 5.5px 6px;
}

.auth__btn--logout {
	background-color: var(--COLOR-WHITE);
	padding: 5.5px 6px;
	color: var(--COLOR-PRIMARY);
}

.auth__btn--logout:hover {
	background-color: var(--COLOR-PRIMARY);
	color: var(--COLOR-WHITE);
}

.form__signup_btn {
	background-color: var(--COLOR-WHITE);
	color: var(--COLOR-PRIMARY);
}

.form__signup_btn:hover {
	background-color: var(--COLOR-PRIMARY);
	color: var(--COLOR-WHITE);
}

li .card {
	/* min-height: 44rem; */
	display: flex;
	flex-flow: column;
	grid-gap: 1rem;
	gap: 1rem;
	justify-content: space-between;
}

li .card__footer {
	margin-bottom: 4px;
}

.card__rating {
	position: absolute;
	top: 4px;
	left: 4px;
	display: flex;
	flex-flow: row nowrap;
	grid-gap: 0;
	gap: 0;
	align-items: center;
	border: 2px solid var(--COLOR-PRIMARY);
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border-radius: 4px;
	font-weight: bold;
	padding-left: 4px;
	cursor: default;
	background-color: var(--COLOR-PRIMARY);
	color: var(--COLOR-WHITE);
	transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card__rating:hover {
	background-color: var(--COLOR-PRIMARY);
	color: var(--COLOR-WHITE);
}

.card__rating .rating {
	padding: 0 3px;
}
.rating i {
	font-size: 1.7rem;
	color: inherit;
	cursor: default;
}
.rating i:hover {
	color: var(--COLOR-WHITE);
	-webkit-transform: scale(1);
	        transform: scale(1);
}

.card .card__icons .like:hover {
	background-color: var(--COLOR-PRIMARY-THEME);
	color: var(--COLOR-PRIMARY-THEME);
	box-shadow: none;
	opacity: 1;
}
.card .like {
	background-color: var(--COLOR-PRIMARY-OPP);
	border: none;
	border-radius: 5px;
	box-shadow: none;
	opacity: 1;
	width: 3.4rem;
	height: 3.4rem;
}
.card .card__icons .like i {
	font-size: 2.8rem;
	color: var(--COLOR-PRIMARY-OPP);
}

.card .card__icons .like i:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}

li .card--vertical_v2 {
	min-height: 44rem;
}

.card .card__icons .card__link {
	width: 3.9rem;
	height: 3.9rem;
	background: var(--COLOR-PRIMARY-THEME);
}

.card--shopping .card__button {
	opacity: 1;
}

.card--cart .card__button {
	padding: 2px 8px;
}

.card--cart .card__button {
	padding: 0.6rem 1rem;
}

.card--cart .card__body {
	padding: 0.8rem 2.5rem;
	grid-gap: 1rem;
	gap: 1rem;
}

.category_card__list {
	position: relative;
}

.catergories__name {
	background-color: var(--COLOR-PRIMARY);
	display: flex;
	font-size: 2.2rem;
	font-weight: 700;
	justify-content: center;
	opacity: 0.7;
	top: 55%;
	position: absolute;
	width: 100%;
	z-index: var(--z-index-2);
	color: var(--COLOR-WHITE);
}

.cart__listing {
	justify-content: center;
}

/* .card .like:hover {
	background-color: default;
	color: default;
} */

.card .like:hover {
	background-color: inherit;
	color: var(--COLOR-PRIMARY);
	box-shadow: none;
	opacity: 1;
}
.card .like {
	background-color: inherit;
	border: none;
	border-radius: 5px;
	box-shadow: none;
	opacity: 1;
}
.card .like i {
	font-size: 3rem;
	color: var(--COLOR-PRIMARY-THEME);
}

.card--horizontal_v2 .card--cart .card--body {
	padding: 0 2rem;
}

.card--cart .card__body {
	min-width: 25rem;
	padding: 5px 2.5rem;
}

.categories__items .categories__list .card {
	border: 0;
}

.card--overview {
	grid-gap: 5rem;
	color: var(--COLOR-TXT);
}

.card--overview:hover {
	box-shadow: none;
}

.card--overview .card__content {
	grid-gap: 1.5rem;
	gap: 1.5rem;
	cursor: default;
}

.card--overview .card__body--overview {
	padding: 20px 20px 10px 0;
}

.product__image--overview {
	width: auto;
	height: 12rem;
}

.card__body--overview {
	display: flex;
	justify-content: left;
	text-align: left;
	flex-flow: column wrap;
	grid-gap: 1rem;
	gap: 1rem;
}

.card__body--overview .card__quantity {
	font-weight: 500;
	font-size: 1.4rem;
}

.overview__card_header {
	text-align: left;
	font-size: 1.7rem;
}

.card__actions .reorder_btn {
	padding: 1px 7px;
}

:root {
	--COLOR-BG-MODAL: rgba(0, 0, 0, 0.45);
}

.modal__wrapper {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: var(--z-index-3);
	background-color: rgba(0, 0, 0, 0.45);
	background-color: var(--COLOR-BG-MODAL);
}

.modal__wrapper_center_child {
	display: flex;
	justify-content: center;
	align-items: center;
}

.backdrop-blur {
	-webkit-backdrop-filter: blur(2px);
	        backdrop-filter: blur(2px);
}
@supports (
	((-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px)))
) {
	.backdrop-blur {
		-webkit-backdrop-filter: blur(2px);
		backdrop-filter: blur(2px);
	}
}

.accordian__wrapper {
	display: flex;
	flex-direction: column;
	grid-gap: 1.6rem;
	gap: 1.6rem;
}

.details {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	background-color: var(--COLOR-BG-DETAILS);
	margin: 0 1rem;
	padding: 6px;
	border-radius: 4px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	grid-gap: 1rem;
	gap: 1rem;
	width: 65vw;
}

.details:hover {
	background-color: var(--COLOR-BG-HOVER-DETAILS);
}

summary {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	padding: 8px;
}

summary i {
	font-size: 2.6rem;
}

details > summary {
	list-style: none;
}

@media only screen and (max-width: 980px) {
	.details {
		width: 55vw;
	}
}

@media only screen and (max-width: 780px) {
	.details {
		width: 85vw;
	}
	.accordian__wrapper {
		align-items: center;
	}
}

@media only screen and (max-width: 780px) {
	.accordian__wrapper {
		margin-bottom: 0.5rem;
	}
}

.addresses {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 1.5rem;
	gap: 1.5rem;
}

.address__add_Btn {
	padding: 5px 8px;
	margin: 1.5rem auto 8px 8px;
}

.address_card {
	position: relative;
	width: 23rem;
	height: 20.5rem;
	border: 1px solid;
	border-radius: 4px;
	padding: 1.2rem;
	justify-content: space-between;
	cursor: pointer;
	background-color: var(--COLOR-BG-ADDRESS-CARD);
	display: flex;
	flex-direction: column;
}

.address_card:hover {
	background-color: var(--COLOR-BG-HOVER-ADDRESS-CARD);
}

.address_card__text .h6 {
	font-size: 2rem;
	font-weight: 500;
	padding: 8px 0;
}

.address_card__text p:nth-child(2) {
	margin-top: 4px;
}

.address_card__text p {
	font-size: 1.6rem;
	margin-bottom: 8px;
}

.address_card__radio {
	position: absolute;
	top: 5px;
	right: 5px;
	width: 15px;
	height: 15px;
}

.address_card__address {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.address_card__cta {
	display: flex;
	justify-content: space-between;
}

.address_card__cta i {
	font-size: 2.4rem;
	color: var(--COLOR-TXT);
}


:root {
	--COLOR-TXT-PRIMARY: var(--COLOR-PRIMARY);
}

.body {
	background-color: var(--COLOR-BG);
	display: grid;
	min-height: 100vh;
	grid-template-columns: 1fr;
	grid-template-rows: 6rem 1fr 4rem;
	grid-template-areas:
		"header"
		"main"
		"footer";
	transition: all 0.3s ease-in;
}

.main--homepage {
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem 10rem;
	grid-gap: 3rem;
	gap: 3rem;
	grid-area: main;
}

.section__heading {
	color: var(--COLOR-TXT);
	font-weight: 500;
}

/* categories section */
.homepage__section {
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 3rem;
	gap: 3rem;
	align-items: center;
}

.categories__items {
	display: flex;
	flex-flow: row wrap;
	grid-gap: 2rem;
	gap: 2rem;
	margin: auto;
	text-align: center;
	width: 100%;
	margin: auto;
}

.categories__list {
	list-style-type: none;
}

ul {
	padding: 0;
}

.categories__links {
	position: relative;
	text-decoration: none;
}

.categories__links > img {
	width: 20rem;
}

.categories__links > span {
	background-color: var(--COLOR-PRIMARY);
	display: flex;
	font-size: 2.2rem;
	font-weight: 700;
	justify-content: center;
	opacity: 0.7;
	position: absolute;
	top: 12.7rem;
	width: 22rem;
	z-index: 3;
	color: var(--COLOR-WHITE);
}

/* promo-section  */
.promotion_container .image--responsive {
	margin: auto;
	max-height: 58rem;
	width: auto;
	aspect-ratio: auto;
}

/* items_container section */

.items_container .categories__items {
	grid-gap: 3rem;
	gap: 3rem;
}

.image--responsive {
	object-fit: contain;
	aspect-ratio: 1/1;
}

.image--responsive:hover {
	object-fit: contain;
	-webkit-transform: none;
	        transform: none;
}

body .btn {
	box-shadow: none;
}

@media only screen and (max-width: 780px) {
	.body {
		grid-template-areas:
			"header"
			"search"
			"main"
			"footer";
		grid-template-columns: 1fr;
		grid-template-rows: 6rem 5rem 1fr 4rem;
		height: auto;
	}

	.categories__links > img {
		max-width: 16rem;
	}
}

/* login page */
:root {
	--COLOR-INPUT-BORDER: #c5c5c5;
}
.center {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
}

a {
	text-decoration: none;
	color: inherit;
	border: none;
	background-color: transparent;
	box-shadow: none;
}

.form__signup_btn {
	opacity: 0.9;
	background: var(--COLOR-LIST-HOVER);
}

.form__signup_btn:hover {
	-webkit-transform: scale(1);
	        transform: scale(1);
	opacity: 1;
}

.form {
	background-color: var(--COLOR-PRIMARY-DARK);
	color: var(--COLOR-TXT);
	display: flex;
	flex-flow: column wrap;
	justify-content: space-around;
	align-content: space-around;
	align-items: flex-start;
	min-width: 50rem;
	grid-gap: 1.5rem;
	gap: 1.5rem;
	padding: 3.5rem;
	box-shadow: 0 5px 10px var(--COLOR-CARD-BOX-SHADOW0),
		0 3px 1px -2px var(--COLOR-BOX-SHADOW1), 0 2px 2px var(--COLOR-BOX-SHADOW2),
		0 1px 5px var(--COLOR-BOX-SHADOW3);
}

.form__login_btn,
.form__signup_btn {
	width: 100%;
}

.form h2 {
	align-self: center;
}

.form > a {
	width: 100%;
	display: flex;
	place-content: center;
}

.input_box {
	font-size: 1.6rem;
	width: 100%;
	border-radius: 4px;
	padding: 5px 1rem;
	border: 1px solid #c5c5c5;
	border: 1px solid var(--COLOR-INPUT-BORDER);
	background-color: var(--COLOR-BG);
}

.input_box:hover {
	border: 1px solid var(--COLOR-PRIMARY);
}

.input_box:focus {
	border: 1px solid var(--COLOR-PRIMARY);
}

.label {
	color: var(--COLOR-TXT);
	font-weight: 400;
}

.form__item {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	width: 100%;
}

.form__item i {
	position: absolute;
	right: 1rem;
	top: 3.1rem;
	font-size: 2.5rem;
}

.checkbox {
	opacity: 0.9;
}

.checkbox:hover {
	opacity: 1;
	cursor: pointer;
}

.form__actions {
	align-items: center;
	flex-flow: row wrap;
	justify-content: flex-start;
}

.form__actions label {
	padding-left: 1rem;
}

.form__actions .forgot_pass {
	opacity: 0.8;
	margin-left: auto;
	font-weight: 400;
}

.form__actions .forgot_pass:hover {
	opacity: 1;
}

/* Button Css */

.form__actions .btn--link:hover {
	background-color: inherit;
	color: inherit;
}

.form__actions .btn:hover {
	box-shadow: none;
	opacity: 1;
}

.form__actions .btn--link {
	background: transparent;
	border: none;
	box-shadow: none;
	color: var(--COLOR-TXT);
	padding: 4px;
	text-decoration: none;
}

.form__password {
	position: relative;
}

.form__item .btn--icon {
	position: absolute;
	right: 4px;
	top: 42px;
	border: none;
	color: var(--COLOR-PRIMARY-THEME);
}


.body--cart {
	display: grid;
	min-height: 100vh;
	grid-template-columns: 1fr;
	grid-template-rows: 6rem 1fr;
	grid-template-areas:
		"header"
		"main";
	transition: all 0.3s ease-in;
	max-width: auto;
	margin: auto;
}

/*-------------------- main cart ------------------------ */
.main--cart {
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem 10rem;
	grid-gap: 3rem;
	gap: 3rem;
}

.main--cart .section__heading,
.main--checkout .section__heading {
	text-align: center;
	font-weight: 600;
}

.cart__section {
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 3rem;
	gap: 3rem;
}

.cart--empty {
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}

.cart--empty .h6 {
	font-size: 1rem;
}

.cart--empty__head {
	color: var(--COLOR-SECONDARY);
}

.cart--empty__content {
	color: var(--COLOR-TXT-PRIMARY);
}

.cart__wrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	grid-gap: 5rem;
	gap: 5rem;
}

.categories__items_cart {
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 2rem;
	gap: 2rem;
}

.underline {
	text-decoration: underline;
}

/*-------------------- body wishlist ------------------------ */


.main--wishlist {
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem 10rem;
	grid-gap: 3rem;
	gap: 3rem;
}

/*-------------------- main wishlist ------------------------ */

.main--wishlist .section__heading {
	text-align: center;
}

.wishlist__section {
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 3rem;
	gap: 3rem;
}

.wishlist_empty {
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}

.wishlist_empty .h6 {
	font-size: 1rem;
}

.wishlist_empty__head {
	color: var(--COLOR-SECONDARY);
}

.wishlist_empty__content {
	color: var(--COLOR-PRIMARY);
}

form .h5 {
	color: var(--COLOR-TXT-PRIMARY);
}



/* HEADER, aside AND LAYOUT */

.product_page_wrapper {
	display: grid;
	grid-template-columns: 25rem calc(100vw - 25rem);
	grid-template-rows: 1fr;
	grid-template-areas: "aside main";
	margin: 1rem auto;
}

.items_container .categories__items {
	grid-gap: 4rem;
	gap: 4rem;
}

/* --aside navigation */
.aside {
	grid-area: aside;
	margin: 2.6rem;
	border: none;
}

.aside__head {
	font-weight: 500;
	margin: 1rem 0 0.5rem 1rem;
	font-size: 2.2rem;
}

.aside__items {
	list-style: none;
	width: 100%;
	grid-gap: 0.6rem;
	gap: 0.6rem;
}

.aside__list {
	width: 120rem;
}

.aside__links {
	display: inline-block;
	text-decoration: none;
	padding: 5px 8px;
	width: 100%;
	border-radius: 8px;
	color: var(--COLOR-WHITE);
}

.aside__links:hover {
	text-decoration: underline;
	background-color: linear-gradient();
}

.aside_link--selected {
	color: black;
	font-weight: 600;
	background-color: var(--COLOR-WHITE);
}

/* MAIN CONTENT */

/* -----filter Form-----  */

.filter {
	color: var(--COLOR-TXT);
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 2.5rem;
	gap: 2.5rem;
}

.filter > * {
	font-size: 1.4rem;
}

.filter__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.filter .filter__header .h5 {
	color: var(--COLOR-TXT);
}

.filter__section .filter__head {
	margin-bottom: 8px;
	color: var(--COLOR-TXT);
}

.filter__head {
	font-weight: 500;
	color: var(--COLOR-TXT);
}

.filter--reset {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-decoration: underline;
	font-size: 1.6rem;
	color: inherit;
}

/* range--filter */

.range__head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 7px;
}

.range__amount {
	list-style: none;
}

.range__sliders {
	-webkit-appearance: none;
	        appearance: none;
	width: 100%;
	background-color: var(--COLOR-TXT-PRIMARY);
	height: 8px;
	border-radius: 8px;
}

.range__sliders:hover::-webkit-slider-thumb {
	cursor: pointer;
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}
.range__sliders:hover::-moz-range-thumb {
	cursor: pointer;
	transform: scale(1.1);
}

/* checkbox--filter  */

.filter__items {
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 8px;
	gap: 8px;
}

.filter__list {
	list-style: none;
	display: flex;
	align-items: center;
	grid-gap: 7px;
	gap: 7px;
}

.filter__checkbox,
.filter__radio {
	width: 1.6rem;
	height: 1.6rem;
	cursor: pointer;
}

.filter__item_label {
	cursor: pointer;
}

/* ---- products main ---   */
.main--products {
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem;
	grid-gap: 3rem;
	gap: 3rem;
	/* height: 150vh; */
}

.main--products .section__heading {
	text-align: center;
}

.products__section {
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 2rem;
	gap: 2rem;
}

/* utilities  */

.display--none {
	display: none;
}

.category__description_container {
	margin: auto;
	max-width: 80%;
}

.category__description {
	font-size: 1.9rem;
}

/* floating filter */

.filter__icon {
	display: none;
	position: fixed;
	top: 13.2rem;
	left: 1rem;
	z-index: var(--z-index-2);
}

.filter__responsive {
	display: flex;
	position: fixed;
	top: 17rem;
	left: -1rem;
	z-index: var(--z-index-2);
	background-color: var(--COLOR-BG);
	border: 1px solid var(--COLOR-PRIMARY-THEME);
	padding: 2rem;
	border-radius: 8px;
	padding-right: 0rem;
	width: 35rem;
}

.filter__responsive .filter {
	color: var(--COLOR-TXT);
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 2.5rem;
	height: 60vh;
	grid-gap: 2.5rem;
	gap: 2.5rem;
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 1rem;
	width: 100%;
}

.scroll_to_top_icon {
	position: fixed;
	bottom: 3rem;
	right: 1.5rem;
	z-index: var(--z-index-2);
}

.page_not_found_wrapper {
	display: flex;
	justify-content: center;
	height: 100vh;
	background-color: var(--COLOR-PRIMARY);
	align-items: center;
}

.page_not_found__body {
	color: var(--COLOR-WHITE);
	text-align: center;
}


.homepage__link {
	text-decoration: underline;
}
/* -------- Billing Section ---------  */

.billing_section {
	max-width: 70vw;
}

.bill_wrapper {
	display: flex;
	flex-flow: column nowrap;
	align-items: space-between;
	grid-gap: 8px;
	gap: 8px;
	background-color: var(--COLOR-WHITE);
	border: 1px solid var(--COLOR-OFFWHITE);
	transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
	border-radius: 5px;
	padding: 3rem 4rem;
	position: -webkit-sticky;
	position: sticky;
	top: 10rem;
}

.bill_wrapper > h3 {
	color: var(--COLOR-BLACK);
}

.bill__items,
.bill__total {
	list-style-type: none;
}

.bill__list,
.bill__total {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.bill__total {
	font-weight: 600;
}

.bill__submit_btn {
	text-align: center;
}

.card__quantity .label {
	color: var(--COLOR-TXT);
}

aside form .h5 {
	color: var(--COLOR-PRIMARY);
}

.cart__wrapper .aside {
	margin: 0;
}

.coupon {
	display: flex;
	justify-content: space-between;
	grid-gap: 1rem;
	gap: 1rem;
}

.coupon .coupon__input_box,
.coupon .coupon__btn {
	padding: 2px 6px;
	margin-top: 1rem;
}

.coupon .coupon__btn {
	font-size: 1.4rem;
}

.coupon .coupon__input_box {
	max-width: 10rem;
}

.main--checkout {
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem;
	grid-gap: 3rem;
	grid-gap: 3rem;
	gap: 3rem;
}

.checkout__wrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-evenly;
	grid-gap: 1rem;
	grid-gap: 1rem;
	gap: 1rem;
}

.details ul {
	margin: auto 8px;
	margin-top: 1rem;
}

.cart--empty .btn {
	padding: 8px;
	font-size: 1.8rem;
}

@media only screen and (max-width: 780px) {
	.checkout__wrapper {
		display: flex;
		flex-flow: column nowrap;
	}

	.address__add_Btn {
		margin: auto;
		margin-top: 1rem;
	}

	.addresses {
		justify-content: center;
	}
}

@media only screen and (max-width: 520px) {
	.addresses_actions {
		display: flex;
		justify-content: center;
	}
}

.address__section {
	background-color: var(--COLOR-PRIMARY-DARK);
	box-shadow: 0 5px 10px var(--COLOR-CARD-BOX-SHADOW0),
		0 3px 1px -2px var(--COLOR-BOX-SHADOW1), 0 2px 2px var(--COLOR-BOX-SHADOW2),
		0 1px 5px var(--COLOR-BOX-SHADOW3);
	padding: 2rem 3rem;
	border-radius: 8px;
	min-width: 30rem;
	margin: 5px;
	color: var(--COLOR-BLACK);
}

.address__section .form {
	padding: 0;
	box-shadow: none;
	margin: 1rem 0;
}

.address__section .h3 {
	color: var(--COLOR-BLACK);
}

.address__header {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.address__section .btn--dismiss {
	border: none;
	font-size: 1rem;
	padding: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--COLOR-PRIMARY);
	color: var(--COLOR-WHITE);
	transition: background-color 30ms ease-in;
}

.address__section .btn--dismiss:hover {
	background-color: var(--COLOR-PRIMARY);
}

.address__section .btn--dismiss i {
	font-size: 2rem;
}

.save_address__btn {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	flex-wrap: wrap;
	transition: background-color 0.1 ease-in;
	grid-gap: 1rem;
	gap: 1rem;
}

.save_address__btn .btn {
	padding: 5px 4rem;
}


