.main--checkout {
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem;
	grid-gap: 3rem;
	gap: 3rem;
}

.checkout__wrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-evenly;
	grid-gap: 1rem;
	gap: 1rem;
}

.details ul {
	margin: auto 8px;
	margin-top: 1rem;
}

.cart--empty .btn {
	padding: 8px;
	font-size: 1.8rem;
}

@media only screen and (max-width: 780px) {
	.checkout__wrapper {
		display: flex;
		flex-flow: column nowrap;
	}

	.address__add_Btn {
		margin: auto;
		margin-top: 1rem;
	}

	.addresses {
		justify-content: center;
	}
}

@media only screen and (max-width: 520px) {
	.addresses_actions {
		display: flex;
		justify-content: center;
	}
}
