:root {
	--COLOR-BG-MODAL: rgba(0, 0, 0, 0.45);
}

.modal__wrapper {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);
	z-index: var(--z-index-3);
	background-color: var(--COLOR-BG-MODAL);
}

.modal__wrapper_center_child {
	display: flex;
	justify-content: center;
	align-items: center;
}

.backdrop-blur {
	backdrop-filter: blur(2px);
}
@supports (
	(-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px))
) {
	.backdrop-blur {
		-webkit-backdrop-filter: blur(2px);
		backdrop-filter: blur(2px);
	}
}
