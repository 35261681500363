/* MEDIA QUERIES  */

@media only screen and (max-width: 900px) {
	html {
		font-size: 56%;
	}

	.main--homepage {
		display: flex;
		flex-flow: column nowrap;
		padding: 1rem 1rem;
	}

	.categories__items {
		justify-content: center;
	}

	.promotion_container .image--responsive {
		width: 98vh;
	}

	body .cart__wrapper {
		flex-flow: column wrap;
		align-items: center;
		gap: 5rem;
		padding-bottom: 2rem;
	}

	body .main--cart,
	body .main--checkout {
		display: flex;
		flex-flow: column nowrap;
		padding: 1rem 2rem;
	}

	body .billing_section {
		max-width: 95vw;
		width: 35rem;
		margin: auto;
		margin-top: 0;
	}

	body button {
		font-size: 1.6rem;
	}

	body .main--homepage {
		display: flex;
		flex-flow: column nowrap;
		padding: 3rem 2rem;
	}

	body .categories__items {
		gap: 1rem;
		padding-left: 0rem;
	}
}
@media only screen and (max-width: 780px) {
	html {
		font-size: 55%;
	}

	:root {
		--WIDTH-MAIN-CONTENT: 100%;
	}

	.body {
		grid-template-areas:
			"header"
			"search"
			"nav"
			"main";
		grid-template-columns: 1fr;
		grid-template-rows: 6rem 5rem 1fr 4rem;
		height: auto;
	}

	body .search_box {
		display: flex;
		justify-content: center;
		background-color: var(--COLOR-WHITE);
		border-radius: 4px;
		overflow: hidden;
		height: 4rem;
	}

	.aside--filter {
		display: none;
	}

	.filter__modal {
		position: absolute;
	}

	body .filter > * {
		font-size: 1.8rem;
	}

	body .filter__icon {
		display: flex;
	}

	body .product_page_wrapper {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: "main";
	}

	body > .header,
	.main {
		padding: 0;
		padding: 1.5rem 3rem 1rem;
	}

	body .display--modifier {
		display: none;
	}

	.social__handles {
		padding-right: 0;
	}

	.nav {
		display: none;
		font-size: 2rem;
	}

	.hamburger_icon {
		display: block;
	}

	.show .nav {
		display: flex;
		z-index: var(--z-index-4);
		position: sticky;
		top: 50px;
		padding-top: 0;
		padding: 0.2rem 0.6rem;
	}

	.show .main {
		display: none;
	}

	.header .search_on_header {
		display: none;
	}

	.search_on_mobile {
		display: flex;
		width: 100%;
	}

	body .form {
		min-width: 40rem;
	}
}

@media only screen and (max-width: 500px) {
	html {
		font-size: 52%;
	}

	.comp_container .brand__text {
		margin-left: 0;
		margin: 0 auto;
	}

	.header__nav_btns {
		gap: 1rem;
	}

	body .brand__text {
		cursor: pointer;
		font-size: 2.2rem;
	}

	body .form {
		min-width: 30rem;
	}
}

@media only screen and (max-width: 400px) {
	html {
		font-size: 47%;
	}

	.nav__links {
		width: 10rem;
	}

	.social__links {
		font-size: 3rem;
	}

	body .brand__text {
		cursor: pointer;
		font-size: 1.8rem;
	}

	body .header__nav_btns {
		display: flex;
		grid-gap: 7px;
		gap: 7px;
		padding-right: 0;
	}
}
