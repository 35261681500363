.addresses {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
}

.address__add_Btn {
	padding: 5px 8px;
	margin: 1.5rem auto 8px 8px;
}

.address_card {
	position: relative;
	width: 23rem;
	height: 20.5rem;
	border: 1px solid;
	border-radius: 4px;
	padding: 1.2rem;
	justify-content: space-between;
	cursor: pointer;
	background-color: var(--COLOR-BG-ADDRESS-CARD);
	display: flex;
	flex-direction: column;
}

.address_card:hover {
	background-color: var(--COLOR-BG-HOVER-ADDRESS-CARD);
}

.address_card__text .h6 {
	font-size: 2rem;
	font-weight: 500;
	padding: 8px 0;
}

.address_card__text p:nth-child(2) {
	margin-top: 4px;
}

.address_card__text p {
	font-size: 1.6rem;
	margin-bottom: 8px;
}

.address_card__radio {
	position: absolute;
	top: 5px;
	right: 5px;
	width: 15px;
	height: 15px;
}

.address_card__address {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.address_card__cta {
	display: flex;
	justify-content: space-between;
}

.address_card__cta i {
	font-size: 2.4rem;
	color: var(--COLOR-TXT);
}
