.category_card__list {
	position: relative;
}

.catergories__name {
	background-color: var(--COLOR-PRIMARY);
	display: flex;
	font-size: 2.2rem;
	font-weight: 700;
	justify-content: center;
	opacity: 0.7;
	top: 55%;
	position: absolute;
	width: 100%;
	z-index: var(--z-index-2);
	color: var(--COLOR-WHITE);
}

.cart__listing {
	justify-content: center;
}
